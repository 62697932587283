import React from "react"
import styles from "./Banner.module.css"

const Banner = () => {
    return (
        <div className={styles.banner}>
            <h1>
                dispatch &<br/>
                transportation<br/>
                service inc
            </h1>
        </div>
    )
}

export default Banner
